
.toastify {
  z-index: 999;
  position: fixed;
  padding: 4px;
  width: $toast-width;
  max-width: 98%;
  color: $font-color;
  box-sizing: border-box;
  &--top-left {
    top: 1em;
    left: 1em;
  }
  &--top-center {
    top: 1em;
    left: 50%;
    margin-left: -($toast-width / 2);
  }
  &--top-right {
    top: 1em;
    right: 2em;
  }

  &--bottom-left {
    bottom: 1em;
    left: 1em;
  }
  &--bottom-center {
    bottom: 1em;
    left: 50%;
    margin-left: -($toast-width / 2);
  }
  &--bottom-right {
    bottom: 1em;
    right: 2em;
  }
  &__img {
    float: left;
    margin-right: 8px;
    vertical-align: middle;
  }
}
