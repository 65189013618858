.sidenav-timerwrapper {
  border-top: 1px solid #001E2E;
  background-color:#001E2E;
  padding-top:5px;
  padding-left: 15px;
  padding-bottom: 5px;
}

.sidenav-timer {
  color: #fff;
  line-height: 1.2;
  font-size: 12px;
}
.sidenav-collapse {
  color: #707070 !important;
  font-size:12px;
  font-weight: 400 !important;
  margin-left: -12px;
  margin-bottom: 0px;
}
.sidenav-collapse:before{
    display: inline-block;
    color: #707070;
    transform: rotate(90deg) !important;
    font-size: 15px;
    height: 26px;
    margin-right: 15px;
    float: left;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-left: 0.3em solid transparent;
}
.sidenav-footer-title{
  font-size: 12px;
  padding-top: 6px;
  margin-bottom: 0px;

}
.sidenav-dropdown-collapse {
  a::after {
    display: none;
  }
}

@media screen and (max-width:1280px){
  
  .sidenav-timer{
  font-size: 11px;
}
  .btn-back-dashboard{
  font-size:11px !important;  
    }
}