.info-video {
  background: #f7f7f7;
  margin-left: -13px;
  margin-right: -15px;
  &__icon {
    background: #f7f7f7;
  }
}

.slick-height {
  max-height: 140px;
}