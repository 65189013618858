/* the tree node's style */
.tree-view {
  overflow-y: hidden;
  padding-left: 0;
  outline: none;
  margin-bottom: 0;
  padding-left: 0;
  list-style-type: none;
}
.tree-view button:focus {
  outline: none !important; 
}
.tree-view_item:hover{
  background-color: #4CBCB7;
}
.tree-view_item {
  border: none;
  background: none;
  min-height: 20px;
  padding-left: 0;
  line-height: 35px;
  width: 100%;
  text-align: left;
  padding: 0;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
}


/* style for the children nodes container */
.tree-view_children {
  padding: 0;
  list-style-type: none;
  padding-right: 1px;
}
.tree-view_children .tree-view_item {
  padding-left: 16px;
}
.tree-view_children a {
  padding-left: 16px;
}


.tree-view_tracking {
  padding: 0;
  list-style-type: none;
  padding-right: 1px;
}

.tree-view_tracking .tree-view_item {
  padding-left: 16px;
}

.tree-view_tracking a {
  padding-left: 56px;
}


.tree-view_children li {
  min-height: 40px;
  line-height: 40px;
}

.tree-view_children-collapsed {
  height: 0px;
}
/*.tree-view_arrow:after {
    content: '▾';
}*/
/*.tree-view_arrow {
  cursor: pointer;
  margin-right: 6px;
  display: inline-block;
  -webkit-user-select: none;
  -moz-user-select: none;ath to modu
  -ms-user-select: none;
  user-select: none;
}
*/
/* rotate the triangle to close it */
.tree-view_arrow-collapsed {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.node {
/*  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;*/
  border-radius: 3px;
  padding: 0 !important;
  color:white;
}

.nodeIcon {
  margin-right: 8px;
  height: 25px;
  width: 25px;
}

.node:hover {
  cursor: pointer;
}

.info, .node {
  padding: 2px 10px 2px 0px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/*
.tree-view_arrow {
  -moz-transition: all 0.1s;
  -o-transition: all 0.1s;
  -ms-transition: all 0.1s;
  -webkit-transition: all 0.1s;
  transition: all 0.1s;
}
*/
.tree-view_arrow-empty {
  color: yellow;
}

@media screen and (max-width: 1280px){
  .tree-view_item{
    font-size: 12px;
    line-height: 30px;
  }
}