$primary-color: #09819A;
@import '~loaders.css/src/animations/line-scale.scss';

.fullPageLoader {
  position: fixed;
  height: 3em;
  width: 3em;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
