.sidenav-footer-alignment {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.logo-image { 
  padding-top: 33px !important;
  padding-right: 50px;
  padding-left: 50px;
}
@media screen and (max-width: 1280px){
   .logo-image{
    padding-bottom: 16px;
    padding-top: 16px;
	} 
}