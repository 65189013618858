.icon{
  height: 30px;
  padding-left: 15px;
  margin-right: 15px;
}
.verify{
  height: 50px;
  background-color: #001E2E;
  color: white;
  vertical-align: middle;
  font-weight: 500;
}

.okay{
  display: inline-block;
  font-weight: normal;
  line-height: 1.25;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: all 0.2s ease-in-out;
  background-color: red;
  color: white;
  margin-left: 10px;
  height: 30px;
  padding-top: 3px;
}
.close{
  float:right;
  height:20px;
}

.verify-now{
  color: white !important;
  cursor: pointer;
}

