/*!
 * Bootstrap v4.0.0-alpha.6 (https://getbootstrap.com)
 * Copyright 2011-2017 The Bootstrap Authors
 * Copyright 2011-2017 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

// Core variables and mixins
@import "./imported/variables";
@import "./imported/mixins";
@import "./imported/custom";
@import "./color";
@import "./table";
@import "./button";
@import "./form";
@import "./breadcrumb";
@import "./dropdown";
@import "./pagination";
@import "./custom-file";

// Reset and dependencies
@import "./imported/normalize";
@import "./imported/print";

// Core CSS
@import "./imported/reboot";
@import "./imported/type";
@import "./imported/images";
@import "./imported/code";
@import "./imported/grid";
@import "./imported/tables";
@import "./imported/forms";
@import "./imported/buttons";

// Components
@import "./imported/transitions";
@import "./imported/dropdown";
@import "./imported/buttongroup";
@import "./imported/inputgroup";
@import "./imported/customforms";
@import "./imported/nav";
@import "./imported/navbar";
@import "./imported/card";
@import "./imported/breadcrumb";
@import "./imported/pagination";
@import "./imported/badge";
@import "./imported/jumbotron";
@import "./imported/alert";
@import "./imported/progress";
@import "./imported/media";
@import "./imported/listgroup";
@import "./imported/responsiveembed";
@import "./imported/close";

// Components w/ JavaScript
@import "./imported/modal";
@import "./imported/tooltip";
@import "./imported/popover";
@import "./imported/carousel";

// Utility classes
@import "./imported/utilities";
