// scss-lint:disable QualifyingElement, DuplicateProperty

// Reboot
//
// Global resets to common HTML elements and more for easier usage by Bootstrap.
// Adds additional rules on top of Normalize.css, including several overrides.


// Reset the box-sizing
//
// Change from `box-sizing: content-box` to `border-box` so that when you add
// `padding` or `border`s to an element, the overall declared `width` does not
// change. For example, `width: 100px;` will always be `100px` despite the
// `border: 10px solid red;` and `padding: 20px;`.
//
// Heads up! This reset may cause conflicts with some third-party widgets. For
// recommendations on resolving such conflicts, see
// https://getbootstrap.com/getting-started/#third-box-sizing.
//
// Credit: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/

html {
    box-sizing: border-box;
  }
  
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
  
  
  // Make viewport responsive
  //
  // @viewport is needed because IE 10+ doesn't honor <meta name="viewport"> in
  // some cases. See https://timkadlec.com/2012/10/ie10-snap-mode-and-responsive-design/.
  // Eventually @viewport will replace <meta name="viewport">.
  //
  // However, `device-width` is broken on IE 10 on Windows (Phone) 8,
  // (see https://timkadlec.com/2013/01/windows-phone-8-and-device-width/ and https://github.com/twbs/bootstrap/issues/10497)
  // and the fix for that involves a snippet of JavaScript to sniff the user agent
  // and apply some conditional CSS.
  //
  // See https://getbootstrap.com/getting-started/#support-ie10-width for the relevant hack.
  //
  // Wrap `@viewport` with `@at-root` for when folks do a nested import (e.g.,
  // `.class-name { @import "bootstrap"; }`).
  @at-root {
    @-ms-viewport { width: device-width; }
  }
  
  
  //
  // Reset HTML, body, and more
  //
  
  html {
    // We assume no initial pixel `font-size` for accessibility reasons. This
    // allows web visitors to customize their browser default font-size, making
    // your project more inclusive and accessible to everyone.
  
    // As a side-effect of setting the @viewport above,
    // IE11 & Edge make the scrollbar overlap the content and automatically hide itself when not in use.
    // Unfortunately, the auto-showing of the scrollbar is sometimes too sensitive,
    // thus making it hard to click on stuff near the right edge of the page.
    // So we add this style to force IE11 & Edge to use a "normal", non-overlapping, non-auto-hiding scrollbar.
    // See https://github.com/twbs/bootstrap/issues/18543
    // and https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/7165383/
    -ms-overflow-style: scrollbar;
  
    // Changes the default tap highlight to be completely transparent in iOS.
    -webkit-tap-highlight-color: rgba(0,0,0,0);
  }
  
  body {
    font-family: $font-family-base;
    font-size: $font-size-base;
    font-weight: $font-weight-base;
    line-height: $line-height-base;
    // Go easy on the eyes and use something other than `#000` for text
    color: $body-color;
    // By default, `<body>` has no `background-color` so we set one as a best practice.
    background-color: $body-bg;
  }
  
  // Suppress the focus outline on elements that cannot be accessed via keyboard.
  // This prevents an unwanted focus outline from appearing around elements that
  // might still respond to pointer events.
  //
  // Credit: https://github.com/suitcss/base
  [tabindex="-1"]:focus {
    outline: none !important;
  }
  
  
  //
  // Typography
  //
  
  // Remove top margins from headings
  //
  // By default, `<h1>`-`<h6>` all receive top and bottom margins. We nuke the top
  // margin for easier control within type scales as it avoids margin collapsing.
  h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: .5rem;
  }
  
  // Reset margins on paragraphs
  //
  // Similarly, the top margin on `<p>`s get reset. However, we also reset the
  // bottom margin to use `rem` units instead of `em`.
  p {
    margin-top: 0;
    margin-bottom: 1rem;
  }
  
  // Abbreviations
  abbr[title],
  // Add data-* attribute to help out our tooltip plugin, per https://github.com/twbs/bootstrap/issues/5257
  abbr[data-original-title] {
    cursor: help;
  }
  
  address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit;
  }
  
  ol,
  ul,
  dl {
    margin-top: 0;
    margin-bottom: 1rem;
  }
  
  ol ol,
  ul ul,
  ol ul,
  ul ol {
    margin-bottom: 0;
  }
  
  dt {
    font-weight: $dt-font-weight;
  }
  
  dd {
    margin-bottom: .5rem;
    margin-left: 0; // Undo browser default
  }
  
  blockquote {
    margin: 0 0 1rem;
  }
  
  
  //
  // Links
  //
  
  a {
    color: $link-color;
    text-decoration: $link-decoration;
  
    @include hover-focus {
      color: $link-hover-color;
      text-decoration: $link-hover-decoration;
    }
  }
  
  // And undo these styles for placeholder links/named anchors (without href)
  // which have not been made explicitly keyboard-focusable (without tabindex).
  // It would be more straightforward to just use a[href] in previous block, but that
  // causes specificity issues in many other styles that are too complex to fix.
  // See https://github.com/twbs/bootstrap/issues/19402
  
  a:not([href]):not([tabindex]) {
    color: inherit;
    text-decoration: none;
  
    @include hover-focus {
      color: inherit;
      text-decoration: none;
    }
  
    &:focus {
      outline: 0;
    }
  }
  
  
  //
  // Code
  //
  
  pre {
    // Remove browser default top margin
    margin-top: 0;
    // Reset browser default of `1em` to use `rem`s
    margin-bottom: 1rem;
    // Normalize v4 removed this property, causing `<pre>` content to break out of wrapping code snippets
    overflow: auto;
  }
  
  
  //
  // Figures
  //
  
  figure {
    // Normalize adds `margin` to `figure`s as browsers apply it inconsistently.
    // We reset that to create a better flow in-page.
    margin: 0 0 1rem;
  }
  
  
  //
  // Images
  //
  
  img {
    // By default, `<img>`s are `inline-block`. This assumes that, and vertically
    // centers them. This won't apply should you reset them to `block` level.
    vertical-align: middle;
    // Note: `<img>`s are deliberately not made responsive by default.
    // For the rationale behind this, see the comments on the `.img-fluid` class.
  }
  
  
  // iOS "clickable elements" fix for role="button"
  //
  // Fixes "clickability" issue (and more generally, the firing of events such as focus as well)
  // for traditionally non-focusable elements with role="button"
  // see https://developer.mozilla.org/en-US/docs/Web/Events/click#Safari_Mobile
  
  [role="button"] {
    cursor: pointer;
  }
  
  
  // Avoid 300ms click delay on touch devices that support the `touch-action` CSS property.
  //
  // In particular, unlike most other browsers, IE11+Edge on Windows 10 on touch devices and IE Mobile 10-11
  // DON'T remove the click delay when `<meta name="viewport" content="width=device-width">` is present.
  // However, they DO support removing the click delay via `touch-action: manipulation`.
  // See:
  // * https://v4-alpha.getbootstrap.com/content/reboot/#click-delay-optimization-for-touch
  // * http://caniuse.com/#feat=css-touch-action
  // * https://patrickhlauke.github.io/touch/tests/results/#suppressing-300ms-delay
  
  a,
  area,
  button,
  [role="button"],
  input,
  label,
  select,
  summary,
  textarea {
    touch-action: manipulation;
  }
  
  
  //
  // Tables
  //
  
  table {
    // No longer part of Normalize since v4
    border-collapse: collapse;
    // Reset for nesting within parents with `background-color`.
    background-color: $table-bg;
  }
  
  caption {
    padding-top: $table-cell-padding;
    padding-bottom: $table-cell-padding;
    color: $text-muted;
    text-align: left;
    caption-side: bottom;
  }
  
  th {
    // Centered by default, but left-align-ed to match the `td`s below.
    text-align: left;
  }
  
  
  //
  // Forms
  //
  
  label {
    // Allow labels to use `margin` for spacing.
    display: inline-block;
    margin-bottom: .5rem;
  }
  
  // Work around a Firefox/IE bug where the transparent `button` background
  // results in a loss of the default `button` focus styles.
  //
  // Credit: https://github.com/suitcss/base/
  button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
  }
  
  input,
  button,
  select,
  textarea {
    // Normalize includes `font: inherit;`, so `font-family`. `font-size`, etc are
    // properly inherited. However, `line-height` isn't inherited there.
    line-height: inherit;
  }
  
  input[type="radio"],
  input[type="checkbox"] {
    // Apply a disabled cursor for radios and checkboxes.
    //
    // Note: Neither radios nor checkboxes can be readonly.
    &:disabled {
      cursor: $cursor-disabled;
    }
  }
  
  
  input[type="date"],
  input[type="time"],
  input[type="datetime-local"],
  input[type="month"] {
    // Remove the default appearance of temporal inputs to avoid a Mobile Safari
    // bug where setting a custom line-height prevents text from being vertically
    // centered within the input.
    // See https://bugs.webkit.org/show_bug.cgi?id=139848
    // and https://github.com/twbs/bootstrap/issues/11266
    -webkit-appearance: listbox;
  }
  
  textarea {
    // Textareas should really only resize vertically so they don't break their (horizontal) containers.
    resize: vertical;
  }
  
  fieldset {
    // Browsers set a default `min-width: min-content;` on fieldsets,
    // unlike e.g. `<div>`s, which have `min-width: 0;` by default.
    // So we reset that to ensure fieldsets behave more like a standard block element.
    // See https://github.com/twbs/bootstrap/issues/12359
    // and https://html.spec.whatwg.org/multipage/#the-fieldset-and-legend-elements
    min-width: 0;
    // Reset the default outline behavior of fieldsets so they don't affect page layout.
    padding: 0;
    margin: 0;
    border: 0;
  }
  
  legend {
    // Reset the entire legend element to match the `fieldset`
    display: block;
    width: 100%;
    padding: 0;
    margin-bottom: .5rem;
    font-size: 1.5rem;
    line-height: inherit;
  }
  
  input[type="search"] {
    // This overrides the extra rounded corners on search inputs in iOS so that our
    // `.form-control` class can properly style them. Note that this cannot simply
    // be added to `.form-control` as it's not specific enough. For details, see
    // https://github.com/twbs/bootstrap/issues/11586.
    -webkit-appearance: none;
  }
  
  // todo: needed?
  output {
    display: inline-block;
  //  font-size: $font-size-base;
  //  line-height: $line-height;
  //  color: $input-color;
  }
  
  // Always hide an element with the `hidden` HTML attribute (from PureCSS).
  [hidden] {
    display: none !important;
  }
  