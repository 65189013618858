.sidenav-dropdown {
  a::after {
    transform: rotate(-90deg);
    float: right;
    margin-right: 5px;
    font-size: 32px;
    color:#fff;
    margin-top: 32px;
    position: absolute;
    top: 0px;
    right: 3px;
  }
  &__title {
    font-weight: bold;
    margin-left: 9px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    // padding-top: 10px;
    line-height: 1;
    font-size: 14px;
    width: 100px;
    display: block !important;
    &--sub {
      @extend .sidenav-dropdown__title;
      font-size: 14px;
    }
  }
  @media screen and (max-width: 1280px){
    a::after {
      transform: rotate(-90deg);
      float: right;
      font-size: 25px;
      margin-top:28px;
    }
   &__title {
      font-size:12px;
   } 
  .sidenav-logo {
  margin-left: 12px;
  max-width: 45px;
  max-height: 45px;
}
  .sidenav-logo-upload{
    margin-bottom: 0px;
    margin-left:12px;
    margin-bottom: 15px;
    max-height: 45px;
    max-width: 45px;

  }
  .company-name{
    // margin-top: -17px;
    font-size: 11px;
  }
  .sidenav-timer{
   font-size: 11px;
  }
}
}
.company-name{
    // margin-top:-16px;
    line-height: 1;
    font-size: 12px;
    padding-left: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block !important;
    white-space: nowrap;
    width: 100px;
    // margin-bottom: -16px;
}

.sidenav-logo {
  margin-bottom: 10px;
  margin-top: 10px;
  width: auto;
  border-radius: 50%;  
  margin-left: 10px;
}

.sidenav-logo-upload {
  width: auto;
  width: 100%;
  height: 100%;
}
.sidenav-logo:active{
   boutline: none !important;
   box-shadow: none;
}
.sidenav-logo:focus{
   outline: none !important;
   box-shadow: none;
}

.sidenav-timerwrapper {
  border-top: 1px solid #EFEFEF !important;
  padding: 10px;
}
.sidenav-timer {
  color: #FFF;
  line-height: 1.2;
  font-size: 12px;
  padding-bottom: 2px;
}
.sidenav-logo-upload-collapse {
  margin: 0;
  width: 100%;
  height: auto;
  max-width: 100%;
}

.sidenav-dropdown-collapse {
  a::after {
    display: none;
  }
}

.image-upload {
  position: relative;
  height: 52px;
  width: 52px;
  /*background: #EFEFEF;*/
  margin: 10px;
}
.image-upload-collapse {
 position: relative;
 height: 45px;
 width: 45px;
 /*background: #EFEFEF;*/
 margin: 5px;
  img {
    position: absolute;
    left: 50%;
    top: 50%;
    height: 100%;
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    object-fit: scale-down;
  }
}

.image-upload img {
   position: absolute;
   left: 50%;
   top: 50%;
   height: 100%;
   -webkit-transform: translate(-50%,-50%);
   -ms-transform: translate(-50%,-50%);
   transform: translate(-50%,-50%);
   object-fit: scale-down;
}

.company-name-section{
  align-self: center;
}

