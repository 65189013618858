.video-wrapper {
  position: relative;
  display: inline-block;
  height: 124px;
  width: 124px;
  overflow: hidden;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.video-content {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 100%;
  -webkit-transform: translate(-50%,-50%);
  -webkit-backface-visibility: hidden;
  -ms-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  object-fit: scale-down;
  max-width: 100%;
}

.overlay-play-button {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 40px;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  opacity: 0.95;
  cursor: pointer;
  background-color: rgba(0,0,0,0);
  transition: opacity 150ms;
}

.video-overlay-play-button.is-hidden {
    display: none;
}
 
.rich-text {
  color: #2b1570;
  font-size: 25px;
  padding-right:60px;
}

.img-wrap {
  position: relative;
  height: 124px;
  width: 124px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-left: 2rem;
}

.document-wrap {
  position: relative;
  display: inline-block;
  height: 124px;
  width: 124px;
  margin-top: 0.5rem;
  margin-left: 2rem;
  margin-bottom: 0.5rem;
}

.modal-img-wrap {
  position: relative;
  height: 450px;
  width: 450px;
}

.modal-img-wrap img {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 100%;
  -webkit-transform: translate(-50%,-50%);
  -webkit-backface-visibility: hidden;
  -ms-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  object-fit: scale-down;
  max-width: 100%;
}

.img-wrap img {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 100%;
  -webkit-transform: translate(-50%,-50%);
  -webkit-backface-visibility: hidden;
  -ms-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  object-fit: scale-down;
  max-width: 100%;
}

.help-model-header {
  padding: 0 5px 0 0 !important;
}

.video-modal-width {
  max-width: 700px !important;
}

.modal-video-wrapper {
  position: relative;
  display: inline-block;
  height: 340px;
  width: 650px;
  overflow: hidden;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
