.wrapper {
  padding-top: 13vw;
  padding-left: 50px;
  text-align: left;
  width: 100%;
  min-height: 100vh;
}

.logo {
  margin-bottom: 2px;
}

.description {
  padding-top: 5px;
  padding-bottom: 20px;
  font-size:15px;
  color: #3d556b;
}

.welcome_text{
	font-size: 240%;
	line-height: 1;
}