// Base class
//
// Easily usable on <ul>, <ol>, or <div>.

.list-group {
    display: flex;
    flex-direction: column;
  
    // No need to set list-style: none; since .list-group-item is block level
    padding-left: 0; // reset padding because ul and ol
    margin-bottom: 0;
  }
  
  
  // Interactive list items
  //
  // Use anchor or button elements instead of `li`s or `div`s to create interactive
  // list items. Includes an extra `.active` modifier class for selected items.
  
  .list-group-item-action {
    width: 100%; // For `<button>`s (anchors become 100% by default though)
    color: $list-group-link-color;
    text-align: inherit; // For `<button>`s (anchors inherit)
  
    .list-group-item-heading {
      color: $list-group-link-heading-color;
    }
  
    // Hover state
    @include hover-focus {
      color: $list-group-link-hover-color;
      text-decoration: none;
      background-color: $list-group-hover-bg;
    }
  
    &:active {
      color: $list-group-link-active-color;
      background-color: $list-group-link-active-bg;
    }
  }
  
  
  // Individual list items
  //
  // Use on `li`s or `div`s within the `.list-group` parent.
  
  .list-group-item {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    padding: $list-group-item-padding-y $list-group-item-padding-x;
    // Place the border on the list items and negative margin up for better styling
    margin-bottom: -$list-group-border-width;
    background-color: $list-group-bg;
    border: $list-group-border-width solid $list-group-border-color;
  
    &:first-child {
      @include border-top-radius($list-group-border-radius);
    }
  
    &:last-child {
      margin-bottom: 0;
      @include border-bottom-radius($list-group-border-radius);
    }
  
    @include hover-focus {
      text-decoration: none;
    }
  
    &.disabled,
    &:disabled {
      color: $list-group-disabled-color;
      cursor: $cursor-disabled;
      background-color: $list-group-disabled-bg;
  
      // Force color to inherit for custom content
      .list-group-item-heading {
        color: inherit;
      }
      .list-group-item-text {
        color: $list-group-disabled-text-color;
      }
    }
  
    // Include both here for `<a>`s and `<button>`s
    &.active {
      z-index: 2; // Place active items above their siblings for proper border styling
      color: $list-group-active-color;
      background-color: $list-group-active-bg;
      border-color: $list-group-active-border;
  
      // Force color to inherit for custom content
      .list-group-item-heading,
      .list-group-item-heading > small,
      .list-group-item-heading > .small {
        color: inherit;
      }
  
      .list-group-item-text {
        color: $list-group-active-text-color;
      }
    }
  }
  
  
  // Flush list items
  //
  // Remove borders and border-radius to keep list group items edge-to-edge. Most
  // useful within other components (e.g., cards).
  
  .list-group-flush {
    .list-group-item {
      border-right: 0;
      border-left: 0;
      border-radius: 0;
    }
  
    &:first-child {
      .list-group-item:first-child {
        border-top: 0;
      }
    }
  
    &:last-child {
      .list-group-item:last-child {
        border-bottom: 0;
      }
    }
  }
  
  
  // Contextual variants
  //
  // Add modifier classes to change text and background color on individual items.
  // Organizationally, this must come after the `:hover` states.
  
  @include list-group-item-variant(success, $state-success-bg, $state-success-text);
  @include list-group-item-variant(info, $state-info-bg, $state-info-text);
  @include list-group-item-variant(warning, $state-warning-bg, $state-warning-text);
  @include list-group-item-variant(danger, $state-danger-bg, $state-danger-text);
  