.selected-tab {
  background-color: #fff !important;
  color:#09819A !important;
  span {
  	color: #09819A !important;
  	-webkit-filter: brightness(0.30);
    filter: brightness(5);
  }
  img {
    color: #fff;
    -webkit-filter: brightness(0.30);
    filter: brightness(5);
  }
  svg {
    path {
      fill: #09819A !important;
    }
    rect {
      fill: #09819A !important;
    }
  }
}
.selected-tab::after{
    display: inline-block;
    color: #EFEFEF;
    transform: rotate(90deg) !important;
    font-size: 43px;
    height: 26px;
    margin-top: 5px;
    float: right;
    content: "";
    border-top: 0.3em solid;
}
.selected-tab::hover{
  background-color: #162D6E !important;
  color:white;
}

.selected-item {
  padding-left: 57px !important;
}
.nodeIconSvg {
  margin-left:25px;
  margin-right: 15px;
  width: 17px;
  vertical-align: sub;
  height: auto;
}
.nodeIconCheck{
    margin-left: 19px;
    margin-right: 15px;
    width: 9px;
    vertical-align: sub;
    margin-bottom: 3px;
}

@media screen and (max-width: 1280px){
  .selected-tab {
    font-size:12px !important;
}
  .selected-tab::after{
    font-size: 43px;
    margin-top: 2px;
  }
}
.nodeIconSvgCollapsed {
  margin: 10px 0; 
  width: 17px;
  height: 17px;
}

.item-selected {
  background-color: #162D6E !important;
  color: #fff !important;
  border-radius: 2px;
  img {
    color: #fff !important;
    -webkit-filter: brightness(0.30);
    filter: brightness(5);
  }
  svg {
    path {
      fill: #fff !important;
    }
  }
}
.navCollapseItem {
  display: block;
  padding: 9px 0px 0px 20px;
  color: #707070 !important;
  height: 36px;
}
navCollapseItemhover {
  &:hover {
    background-color: #9CDAD7;
    color: black;
  }
}
.navCollapseMainItem {
  &:hover {
    background-color: #9CDAD7;
    color: black;
  }
}

.tooltip-custom {
  position: relative;
  display: inline-block;
  width: 100%;
  &:hover {
    background-color: #9CDAD7;
  }
}

.tooltip-custom .tooltiptext {
  visibility: hidden;
  background-color: #FFF;
  color: #000;
  text-align: left;
  border-radius: 3px;
  position: absolute;
  border: 1px solid #ddd;
  top: 0px;
  left: 55px;
  z-index: 9999;
  width: 200px;
}

.tooltip-custom:hover .tooltiptext {
  visibility: visible;
}

.a-tag{
  min-height: 20px;
  line-height: 35px;
  font-size: 14px;
  color: #fff;
  display: block;
  font-weight: 500;
  cursor: pointer;
}

.a-tag:hover{
  background-color: #4CBCB7;

}

.survey-tab-height{
  line-height: 35px;
}