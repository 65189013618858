.popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: $zindex-popover;
    display: block;
    max-width: $popover-max-width;
    padding: $popover-inner-padding;
    // Our parent element can be arbitrary since tooltips are by default inserted as a sibling of their target element.
    // So reset our font and text properties to avoid inheriting weird values.
    @include reset-text();
    font-size: $font-size-sm;
    // Allow breaking very long words so they don't overflow the popover's bounds
    word-wrap: break-word;
    background-color: $popover-bg;
    background-clip: padding-box;
    border: $popover-border-width solid $popover-border-color;
    @include border-radius($border-radius-lg);
    @include box-shadow($popover-box-shadow);
  
  
    // Popover directions
  
    &.popover-top,
    &.bs-tether-element-attached-bottom {
      margin-top: -$popover-arrow-width;
  
      &::before,
      &::after {
        left: 50%;
        border-bottom-width: 0;
      }
  
      &::before {
        bottom: -$popover-arrow-outer-width;
        margin-left: -$popover-arrow-outer-width;
        border-top-color: $popover-arrow-outer-color;
      }
  
      &::after {
        bottom: -($popover-arrow-outer-width - 1);
        margin-left: -$popover-arrow-width;
        border-top-color: $popover-arrow-color;
      }
    }
  
    &.popover-right,
    &.bs-tether-element-attached-left {
      margin-left: $popover-arrow-width;
  
      &::before,
      &::after {
        top: 50%;
        border-left-width: 0;
      }
  
      &::before {
        left: -$popover-arrow-outer-width;
        margin-top: -$popover-arrow-outer-width;
        border-right-color: $popover-arrow-outer-color;
      }
  
      &::after {
        left: -($popover-arrow-outer-width - 1);
        margin-top: -($popover-arrow-outer-width - 1);
        border-right-color: $popover-arrow-color;
      }
    }
  
    &.popover-bottom,
    &.bs-tether-element-attached-top {
      margin-top: $popover-arrow-width;
  
      &::before,
      &::after {
        left: 50%;
        border-top-width: 0;
      }
  
      &::before {
        top: -$popover-arrow-outer-width;
        margin-left: -$popover-arrow-outer-width;
        border-bottom-color: $popover-arrow-outer-color;
      }
  
      &::after {
        top: -($popover-arrow-outer-width - 1);
        margin-left: -$popover-arrow-width;
        border-bottom-color: $popover-title-bg;
      }
  
      // This will remove the popover-title's border just below the arrow
      .popover-title::before {
        position: absolute;
        top: 0;
        left: 50%;
        display: block;
        width: 20px;
        margin-left: -10px;
        content: "";
        border-bottom: 1px solid $popover-title-bg;
      }
    }
  
    &.popover-left,
    &.bs-tether-element-attached-right {
      margin-left: -$popover-arrow-width;
  
      &::before,
      &::after {
        top: 50%;
        border-right-width: 0;
      }
  
      &::before {
        right: -$popover-arrow-outer-width;
        margin-top: -$popover-arrow-outer-width;
        border-left-color: $popover-arrow-outer-color;
      }
  
      &::after {
        right: -($popover-arrow-outer-width - 1);
        margin-top: -($popover-arrow-outer-width - 1);
        border-left-color: $popover-arrow-color;
      }
    }
  }
  
  
  // Offset the popover to account for the popover arrow
  .popover-title {
    padding: $popover-title-padding-y $popover-title-padding-x;
    margin-bottom: 0; // Reset the default from Reboot
    font-size: $font-size-base;
    background-color: $popover-title-bg;
    border-bottom: $popover-border-width solid darken($popover-title-bg, 5%);
    $offset-border-width: calc(#{$border-radius-lg} - #{$popover-border-width});
    @include border-top-radius($offset-border-width);
  
    &:empty {
      display: none;
    }
  }
  
  .popover-content {
    padding: $popover-content-padding-y $popover-content-padding-x;
  }
  
  
  // Arrows
  //
  // .popover-arrow is outer, .popover-arrow::after is inner
  
  .popover::before,
  .popover::after {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
  }
  
  .popover::before {
    content: "";
    border-width: $popover-arrow-outer-width;
  }
  .popover::after {
    content: "";
    border-width: $popover-arrow-width;
  }
  