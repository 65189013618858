
.sidenav-dropdown-collapse {
  a::after {
    display: none;
  }
}
.notification-list{
   font-weight: 600;
   white-space: initial;
   font-size: 14px;
   color: black;
   line-height: 1.4rem;
}
.notification-list-wrapper{
 display: flex;
 flex-direction:row;
 align-items:center;
 padding:5px 10px;
 gap:10px
}
.notification-list-wrapper:hover{
  background: lightblue;
  cursor: pointer;
}
.notification-list-wrapper:hover  .delteIcon {
  display: block !important;
}

.notification-list a {
  color: #0e84ad !important;
  text-decoration: underline;
  display: inline !important;
  text-transform: uppercase;
  font-size: 14px !important;
  font-weight: 600 !important;
}
.notification-list a:hover {
  color: #fbab31;
}

.notifcation-header{
  width: 100%;
  height: 45px;
  background: #09819A;
  color: white;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px; 
}
.empty-screen{
  width: 350px;
  height: 375px;
  overflow: hidden;
}
.notification-dot {
  height: 10px;
  width: 10px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  left: 23px;
  top:10px
}
.notification-read-dot{
  height: 10px;
  width: 10px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  left: -15px;
  
}
.image-upload-collapse {
  position: relative;
  height: 45px;
  width: 45px;
  /*background: #EFEFEF;*/
   img {
     position: absolute;
     left: 50%;
     top: 50%;
     height: 100%;
     -webkit-transform: translate(-50%,-50%);
     -ms-transform: translate(-50%,-50%);
     transform: translate(-50%,-50%);
     object-fit: scale-down;
   }
 }
 .dropdownMenu{
  top:0px !important;
  left:-185px !important;
  width: 350px;
  height: 450px;
  padding-top: 0px !important;
 }
 .dropdownMenu-collapse{
  width: 350px;
  height: 450px;
  padding-top: 0px !important;
 }
.close-icon{
   height: 32px;
   width: 32px;
   cursor: pointer;
}
.menu-icon{
  cursor: pointer;
}
.delteIcon {
  display: none
}
.menu-list{
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}
.menu-list-text{
  font-weight: 500;
  font-size: 16px;
  margin-top: 5px;
}
.menu-popover-tooltip-wrapper{
	background-color: white !important;
	padding: 10px !important;
  width:200px;
  border-radius: 7px !important;
  left: 0px !important;
  height: 50px;
  font-weight: 500;
  box-shadow: rgba(0, 0, 0, 0.2) -10px 10px 15px;
  &:after {
    border-top-color: darkgrey;
  }
  margin-bottom: 50px !important;
  margin-left: -135px !important;
}